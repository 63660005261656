$primary-color: #73c167 !default;

$payment-main-color: $primary-color;
$payment-lighter-color: lighten($payment-main-color, 15%) !default;
$payment-light-color: lighten($payment-main-color, 10%) !default;
$payment-dark-color: darken($payment-main-color, 10%) !default;
$payment-darker-color: darken($payment-main-color, 15%) !default;
$payment-gray-color: #6d6e71 !default;

@import '../../institutional.scss';
